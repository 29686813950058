/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
$mdi-font-path: "~@mdi/font/fonts";
@import "~@mdi/font/scss/materialdesignicons.scss";

body {
  margin: 0;
  overflow: auto;
  position: relative;
}

.centered {
  text-align: center;
}

.huge {
  line-height: 1.5em;
  font-size: 8em;
}

.mat-cell {
  span {
    font-size: 1.6em;
  }
}

.mdi_disable_icon {
  color: #c1c3be;
}

.mat-success {
  background-color: #77b93a;
  color: #fff;
}